<template>
  <div>
    <v-row dense>
      <v-col
        cols="12" sm="12" md="6" lg="3" xl="2"
        v-for="(link, i) in aboutLinks"
        :key="i"
      >
        <v-card :href="link.href" target="_blank" class="py-5 font-weight-bold" dark>
          {{ link.text }}
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Contact",

  components: {},

  data: () => ({
    aboutLinks: [
      {
        text: "LinkedIn",
        href: "https://www.linkedin.com/in/gregorysobotka/"
      },
      {
        text: "GitHub",
        href: "https://github.com/gregorysobotka"
      },
      {
        text: "StackExchange",
        href: "https://stackexchange.com/users/9064630/gregory-sobotka"
      },
      {
        text: "Medium",
        href: "https://medium.com/@gregorysobotka"
      }
    ]
  })
};
</script>

<template>
  <h2 class="font-weight-  mt-5 mb-3 text-h4">
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: "PageHeadline",
  data: () => ({})
};
</script>

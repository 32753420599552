<template>
  <div>
    <v-row justify="center" dense>
      <PageHeadline>
        <slot name="title"></slot>
      </PageHeadline>
    </v-row>
    <v-row justify="center" dense>
      <v-col sm="12" md="8" lg="8" xl="4" class="px-4 text-center">
        <div class="body-1">
          <slot name="content"></slot>
        </div>
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CenteredContent",

  components: {},
  props: {},
  methods: {},
  computed: {},
  data: () => ({})
};
</script>

<template>
  <div>
    <v-container class="pa-0" fluid>
      <Parallax :title="title" :subTitle="subTitle" />
    </v-container>
    <v-container>
      <div class="mb-15 pb-15">
        <CenteredContent>
          <template v-slot:title>About</template>
          <!-- <template v-slot:content>(information)</template> -->
          <template v-slot>
            <p class="mb-10">
              I have enjoyed building, improving, and fixing things for as long as I can remember. Recently (the past decade) I have focused this energy on
              <span class="text-decoration-underline">Building Software & Building Teams that Build Software</span>
            </p>
            <ContactInformation />
          </template>
        </CenteredContent>
      </div>
    </v-container>
  </div>
</template>

<script>
import ContactInformation from "@/components/ContactInformation";
import Parallax from "@/components/Parallax";

export default {
  name: "Home",

  components: {
    ContactInformation,
    Parallax
  },
  data: () => ({
    title: "Gregory Sobotka",
    subTitle: "Builder | Technology Leader | Science Enthusiast"
  })
};
</script>

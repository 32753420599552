import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import GlobalComponents from "@/globalComponents";

Vue.config.productionTip = false;

// Register Global Components
GlobalComponents.forEach(component => Vue.component(component.name, component));

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

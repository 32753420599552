<template>
  <v-parallax
    :src="require('@/assets/backgrounds/seattle2.jpeg')"
    alt="Background Image"
    :height="imageHeight"
    dark
  >
    <v-row align="center" justify="center">
      <v-sheet class="text-center intro px-15 py-10" color="rgba(0, 0, 0, 0.8)" dark>
        <h1 class="display-1 font-weight-thin text-h4 mb-3">
          {{ title }}
        </h1>
        <h4>
          {{ subTitle }}
        </h4>
      </v-sheet>
    </v-row>
    <slot></slot>
    <v-overlay z-index="-1" opacity="0.5" absolute/>
  </v-parallax>
</template>

<style lang="scss">
.intro {
  min-width: 400px;
  max-width: 800px;
}
</style>

<script>
import { Random } from "random-js";
const random = new Random();

export default {
  name: "Parallax",

  components: {},
  props: {
    title: String,
    subTitle: String
  },
  methods: {
    // backgroundUrl() {}
  },
  computed: {
    backgroundUrl() {
      const randumNumber = random.integer(1, 4);
      return require(`@/assets/backgrounds/seattle${randumNumber}.jpeg`);
    }
  },
  data: () => ({
    imageHeight: 400,
    backgrounds: [
      {
        imageUrl: require("@/assets/backgrounds/seattle1.jpeg")
      },
      {
        imageUrl: require("@/assets/backgrounds/seattle2.jpeg")
      }
    ]
  })
};
</script>

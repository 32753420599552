<template>
  <v-app>
    <v-app-bar app dark>
      <div class="d-flex align-center">
        <v-btn to="/" icon>
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn to="/about" text>
        About
      </v-btn>
      <!-- <v-btn to="/contact" text>
        Contact
      </v-btn> -->
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
  })
};
</script>
